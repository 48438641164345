const pipe = require('lodash/fp/pipe');
const isEmpty = require('lodash/fp/isEmpty');
const getOr = require('lodash/fp/getOr');
const trim = require('lodash/fp/trim');
const negate = require('lodash/fp/negate');
const join = require('lodash/fp/join');

const getTrimedString = pipe(getOr(''), trim);

const addNewLineToString = string => {
  return string ? `${string}\n` : string;
};

const isNotEmpty = negate(isEmpty);

const joinAndTrimParagraphs = pipe(join(`\n\n`), trim);

const removeMultipleNewLines = string => string.replace(/[\r\n]{3,}/g, '\n\n');

const envShortNameMapping = {
  production: 'prod',
  development: 'dev',
};

module.exports = {
  isNotEmpty,
  joinAndTrimParagraphs,
  getTrimedString,
  addNewLineToString,
  removeMultipleNewLines,
  envShortNameMapping,
};
