/* eslint-disable no-unused-expressions */
import { configForDataset } from '../backend/config/intlConfig';

const sanityJson = require('../backend/sanity.json');

const initCallbackOnEvent = cb =>
  function eventCallback(event) {
    cb();
    // remove the event listener that got triggered
    event.currentTarget.removeEventListener(event.type, eventCallback);
  };

const initAsyncScript = (scriptKey, scriptUrl, onLoad) => () => {
  if (window[scriptKey]) {
    return false;
  }

  window[scriptKey] = true;
  const script = document.createElement('script');

  script.type = 'text/javascript';
  script.async = true;
  script.src = scriptUrl;

  if (typeof onLoad === 'function') {
    script.onload = onLoad;
  }

  document.head.appendChild(script);
};

const siteConfig = configForDataset(sanityJson.api.dataset);
const initGTM = initAsyncScript(
  'gtmDidInit',
  `https://www.googletagmanager.com/gtm.js?id=${siteConfig.googleTagManagerId}`,
  function onLoad() {
    dataLayer.push({
      // ensure PageViews are always tracked
      event: 'gtm.js',
      'gtm.start': new Date().getTime(),
      'gtm.uniqueEventId': 0,
    });
  }
);

const initLogRocket = initAsyncScript(
  'logRocketDidInit',
  'https://cdn.lr-ingest.io/LogRocket.min.js',
  function onLoad() {
    window.LogRocket && window.LogRocket.init('idzq6h/cornerstoneondemandcom');
  }
);

const initBizible = initAsyncScript(
  'bizibleDidInit',
  'https://cdn.bizible.com/scripts/bizible.js'
);

const addScriptLoader = scriptOnEvent => () => {
  const initScript = initCallbackOnEvent(scriptOnEvent);
  document.addEventListener('scroll', initScript);
  document.addEventListener('mousemove', initScript);
  document.addEventListener('touchstart', initScript);
};

const addGTMLoader = addScriptLoader(initGTM);
const addLogRocketLoader = addScriptLoader(initLogRocket);
const addBizibleLoader = addScriptLoader(initBizible);

export default () => {
  document.onreadystatechange = function onReady() {
    if (document.readyState !== 'loading') {
      setTimeout(initGTM, 3500);
      setTimeout(initLogRocket, 3500);
      setTimeout(initBizible, 3500);
    }
  };

  addGTMLoader();
  addLogRocketLoader();
  addBizibleLoader();
};
