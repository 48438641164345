/* eslint-disable import/prefer-default-export */
import axios from 'axios';

const axiosCSODApi = axios.create({
  baseURL:
    typeof window !== 'undefined' &&
    window.location.origin === 'http://localhost:8000'
      ? 'http://localhost:9000'
      : '/.netlify/functions',
  timeout: 3000,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: false,
});

export const getAuth0Token = () =>
  axiosCSODApi.get(`/csod_auth_token`, {
    params: {
      clientId: process.env.GATSBY_CSOD_API_CLIENT_ID,
      clientSecret: process.env.GATSBY_CSOD_API_CLIENT_SECRET,
      grantType: 'client_credentials',
      scope: 'jobrequisition:read',
    },
  });

export const getJobRequisitions = (token = {}) =>
  axiosCSODApi({
    url: `/csod_job_requisitions?token=${token.access_token}`,
    method: 'GET',
  });
