import React from 'react';
import Cookie from 'js-cookie';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/fp/isEmpty';
import { getAuth0Token } from '../api/csodApi';

import fetchStateReducer, {
  INITIAL_STATE,
  actionCreators,
} from '../reducers/fetchState';

export const CsodApiTokenContext = React.createContext();

const MILLISECONDS_IN_ONE_HOUR = 3600;

const safelyParseJSON = string => {
  try {
    return JSON.parse(string);
  } catch (error) {
    return null;
  }
};

export const CsodApiTokenProvider = ({ children }) => {
  const tokenCookie = Cookie.get('csod_api_token');
  const [state, dispatch] = React.useReducer(fetchStateReducer, {
    ...INITIAL_STATE,
    data: tokenCookie ? safelyParseJSON(tokenCookie) : INITIAL_STATE.data,
  });
  React.useEffect(() => {
    if (process.env.GATSBY_PREVIEW_ENV) {
      dispatch(actionCreators.success(null));
    } else {
      if (!isEmpty(state.data)) {
        return;
      }
      dispatch(actionCreators.loading());
      getAuth0Token()
        .then(response => {
          const token = response.data;
          dispatch(actionCreators.success(token));
          Cookie.set('csod_api_token', token, {
            expires: token.expires_in / MILLISECONDS_IN_ONE_HOUR / 24,
          });
        })
        .catch(error => {
          dispatch(actionCreators.failure(error.toString()));
        });
    }
  }, [dispatch]);
  return (
    <CsodApiTokenContext.Provider value={{ token: state.data }}>
      {children}
    </CsodApiTokenContext.Provider>
  );
};

CsodApiTokenProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node.isRequired,
    PropTypes.func.isRequired,
  ]).isRequired,
};
