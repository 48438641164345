import { applyMiddleware, createStore, compose } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import courseReducer from '../reducers/reducers';

const middleware = applyMiddleware(promise, thunk, logger);

// add for redux devtools by wrapping function around "middleware"
const composeEnhancers =
  typeof window !== 'undefined'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : false;

const composeMiddleware = composeEnhancers
  ? composeEnhancers(middleware)
  : middleware;

const store = createStore(courseReducer, composeMiddleware);

export default store;
