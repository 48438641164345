import React from 'react';
import { Provider } from 'react-redux';
import store from './src/state/createStore';
import { CsodApiTokenProvider } from './src/context/CsodApiTokenContext';

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts

  return (
    <Provider store={store}>
      <CsodApiTokenProvider>{element}</CsodApiTokenProvider>
    </Provider>
  );
};
