/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// code require to use redux and wrap provider around App
import wrapWithProvider from './wrap-with-provider';
import clientEntry from './client-entry';

export const wrapRootElement = wrapWithProvider;
export const onClientEntry = clientEntry;
