const initialState = {
  courses: [],
  selectedFilters: {
    courseLanguage: [],
    provider: [],
    subscription: [],
    subject: [],
  },
  fetching: false,
  fetched: false,
  error: null,
};

// export default setFilter;

const coursesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_TO_FILTER': {
      return {
        selectedFilters: {
          ...state.selectedFilters,
          [action.category]: [
            ...state.selectedFilters[action.category],
            action.title,
          ],
        },
      };
    }
    case 'UPDATE_COURSES': {
      return Object.assign({}, state, {
        courses: action.payload,
      });
    }
    case 'REMOVE_FROM_FILTER': {
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [action.category]: [...state.selectedFilters[action.category]].filter(
            title => title !== action.title
          ),
        },
      };
    }
    case 'FETCH_ERROR': {
      return { ...state, fetching: false, error: action.payload };
    }

    default:
      return state;
  }
};

export default coursesReducer;
